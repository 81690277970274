<template>
  <section class="security">
    <!-- <div class="content-header-img mb-1">
      <b-img
        v-if="imgSecurity"
        :src="imgSecurity"
        alt="header image"
      />
    </div> -->
    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <img
          :src="`${this.$urlBucketImage}/security.jpg`"
          class="img-fluid"
          style="border-radius: 25px; width: 450px"
        />
      </div>
    </div>
    <div>
      <span class="d-flex align-items-center">
        <h1>
          {{securityTitle}}
        </h1>
        <h3
          class="ml-2"
          v-if="userPlan.plan === 2"
        >
<!--          <b-badge
            pill
            class=" blue-color-background white-color"
          >
            plus
          </b-badge>-->
        </h3>

        <!-- <b-badge
          v-if="userPlan.plan < 2"
          pill
          class="ml-2 badge-success"
          :to="{ name: 'pages-pricing' }"
        >
          upgrade
        </b-badge> -->
      </span>

      <div class="row" style="margin-top:15px; margin-bottom:15px">
        <div class="col-12 col-md-9">
          <p>
            As security isn't just physical. SlothMove Security supports you with protecting your home,
            your online data cyber security, preventing or reducing-spam and fraud exposure, and even
            property alerts for your home.
          </p>
        </div>
      </div>
    </div>

    <b-row>
      <b-col
        xs="6"
        sm="6"
        md="3"
        class="col-security mb-2"
        v-on:click="handleActivateCyber"
      >
        <b-card
          class="security-card main-cards"
          :class="{'security-card-active': cyberActive}"
        >
          <div class="d-flex justify-content-center align-items-center h-100">
            <div>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="cyber"
                  :src="cyber"
                  alt="basic img"
                />

              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Cyber</h3>
                <b-card-text
                  class="text text-center"
                >
                  Monthly updates on any data breaches or leakages against your personal data
                </b-card-text>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col
        xs="6"
        sm="6"
        md="3"
        class="col-security mb-2"
        v-on:click="handleActivateAntiSpam"
      >
        <b-card
          class="security-card main-cards"
          :class="{'security-card-active': antiSpamActive}"
        >
          <div class="d-flex justify-content-center align-items-center h-100">
            <div>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_anti_spam"
                  :src="security_anti_spam"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Anti-Spam</h3>
                <b-card-text
                  class="text text-center"
                >
                  Unlist and stay removed from from public sale, spam and marketing list your data.
                </b-card-text>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col
        xs="6"
        sm="6"
        md="3"
        class="col-security mb-2 main-cards"
        v-on:click="handleActivateHomeAlert"
      >
        <b-card
          class="security-card"
          :class="{'security-card-active': homeAlertActive}"
        >
          <div class="d-flex justify-content-center align-items-center h-100">
            <div>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_home_alert"
                  :src="security_home_alert"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Home alert</h3>
                <b-card-text
                  class="text text-center"
                >
                  Maintain a property alert with the HM Land Registry you will to limit your risk of fraud.
                </b-card-text>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>

      <!-- PHYSICAL -->
      <!-- <b-col
        xs="6"
        sm="6"
        md="3"
        class="col-security"
        v-on:click="handleActivatePhysical"
      >
        <b-card
          class="security-card"
          :class="{'security-card-active': physicalActive}"
        >
          <div
            class="center header-image"
          >
            <b-img
              v-if="security_physical "
              :src="security_physical"
              alt="basic img"
            />

          </div>

          <div class="card-body-info">
            <h3 class="mt-2 text-center">Physical</h3>
            <b-card-text
              class="text"
            >
              Hardware and software throughout your home to increase safety and reassurance.
            </b-card-text>
          </div>
        </b-card>
      </b-col> -->
    </b-row>

    <b-row>
      <b-col
        v-if="cyberActive"
      >
        <h2>SlothMove Cyber</h2>
        <p>
          SlothMove Cyber Anti-Fraud cross-references your email address against know data breaches,
          using sources such as HIBP to identify any compromise in your personal data. As a plus user you,
          we'll this report every month and alert you of any new breaches. You'll need to upgrade to use this feature.
        </p>
        <div>
          <b-button
            variant="dark"
            class="mt-1 btn-sky"
            v-on:click="handleCyberLaunchAnalysis"
            :disabled="cyberLoading || userPlan.plan !== 2"
          >
            <span
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
              v-if="cyberLoading"
            ></span>
            {{ cyberTextButtom }}
          </b-button>
        </div>

        <b-card
          class="mt-2 pl-2 pr-4 pt-2 pb-2"
          v-if="showMessageEmptyCyberBreaches"
        >
          <h2 class="red-color">Data Breach Alert</h2>
          <p>
            As part of the SlothMove Anti-Fraud service, SlothMove cross-references your email address
            across known data breaches to se if your personal data has been compromised and advise you
            of any vulnerabilities. This service supports in the protection of your transaction with
            Taylor Rose MW, providing you personalised data insights
          </p>

          <div>
            <span class="red-color">Email: </span>
            <span>{{ user.email }}</span>
          </div>
          <div>
            <span class="red-color">Breach: </span>
            <span>No confirmed data breaches</span>
          </div>
        </b-card>

        <b-card
          class="mt-2 pl-2 pr-4 pt-2 pb-2"
          v-if="showMessageEmptyCyberBreaches"
        >
          <h2 class="red-color">It's great news</h2>
          <p>
            We have cross-referenced your email address against known data leaks, hack and other forms
            of compromisation effecting millions of people in our databases. We haven't found and breaches
            in your personal data against those leaks. We still advise you to remain diligent in your
            transaction and follow Anti-Fraud best practice
          </p>
        </b-card>

        <b-card
          class="security-card-cyber-data mt-2"
          v-if="cyberBreaches.length"
        >
          <div
            class="center header-card-cyber-data"
          >
            <h2>{{ user.firstName }} {{ user.lastName }}</h2>
          </div>

          <div class="card-body-info">
            <b-card-text
              class="text"
            >
              Email: {{ user.email }}
            </b-card-text>

            <b-card-text
              class="text"
            >
              Breach: {{ cyberBreaches.length }} confirmed data breanh(es)
            </b-card-text>

            <div
              v-for="(breach, index) in cyberBreaches"
              :key="index"
              class="mt-3"
            >
              <div class="img-cyber-data">
                <b-img
                  v-if="security_physical && !physicalActive"
                  :src="breach.LogoPath"
                  :alt="breach.Name"
                />
              </div>
              <h3 class="mt-1">Breach {{(index + 1)}}: {{breach.Name}}</h3>
              <div v-html="breach.Description" class="mt-1"></div>
              <p class="mt-1">
                compromised Data:
                <span
                  v-for="(data, dataIndex) in breach.DataClasses"
                  :key="dataIndex"
                >
                  {{data}},
                </span>
              </p>
            </div>

            <div class="mt-2">
              <h1>What's next?</h1>
              <p>
                If you have had any data compromised the first point of action should be updating your password with that particular provider.
              </p>

              <b>Update Your Password</b>
              <p>
                If you have had any data compromised the first point of action should be updating your password with that particular provider
              </p>

              <b>Check if that password is used elsewhere</b>
              <p>
                Take a moment to go through your accounts to see if the same leaked password might be used elsewhere.
                If it is, change the password straight away
              </p>

              <b>Protect yourself</b>
              <p>
                If you're using a macbook or Google Chrome they have in-built features to create unique passwords for each site.
                You can stay protected by using a company like <a href="https://1password.com/es/">1 password</a>,
                who will generate and store unique passwords
              </p>
            </div>

            <div class="d-flex  flex-column align-items-end">
              <p>re-run with another email</p>
              <b-form-input
                placeholder="Email for send the results"
                v-model="cyberEmailForSendData"
              />
              <b-button
                variant="warning"
                class="mt-1 btn-sky"
                v-on:click="handleSubmitCyberEmail"
                :disabled="cyberSubmitEmailLoading"
              >
                <span
                  class="spinner-border spinner-border-sm red-color-background"
                  aria-hidden="true"
                  v-if="cyberSubmitEmailLoading"
                ></span>
                  Send me the results
              </b-button>

              <p
                v-if="showMessageSendEmail"
              >
                Excellent, a report has been sent to your email
              </p>
            </div>
          </div>
        </b-card>

      </b-col>

      <b-col
        v-if="antiSpamActive"
      >
        <h2>Anti-Spam</h2>
        <p>
          Reduce inbound spam emails, sales and marketing calls, and phishing attempts by
          using SlothMove Anti-Spam. You can remove yourself and stay unlisted from DBS
          sales & marketing lists and register with the Telephone Preference Service (TPS)
        </p>

        <b-row>
          <b-col
            sm="6"
            md="3"
          >
            <b-card
              class="security-card-anti-spam"
            >
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_anti_spam_TPS"
                  :src="security_anti_spam_TPS"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">TPS</h3>
                <b-card-text
                  class="text"
                >
                  Prevent unsolicited calls through remaining on the Telephone Preference Service
                </b-card-text>

                <div
                  class="check-button"
                >
                  <b-form-checkbox
                    id="TPS"
                    v-model="TPS"
                    name="check-TPS"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col
            sm="6"
            md="3"
          >
            <b-card
              class="security-card-anti-spam"
            >
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_anti_spam_DBS"
                  :src="security_anti_spam_DBS"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">DBS</h3>
                <b-card-text
                  class="text"
                >
                  Reduce spam and phishing, remove yourself from sales and marketing lists
                </b-card-text>

                <div
                  class="check-button"
                >
                  <b-form-checkbox
                    class="check-button"
                    id="DBS"
                    v-model="DBS"
                    name="check-DBS"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <h2>Telephone Preference Service</h2>
        <p>
          The Telephone Preference Service (TPS) is the UK's only official 'Do Not Call' register for
          landline and mobile numbers. It allows peopke and businesses to opt out of unsolicited live
          sales and marketing calls across the UK. Through SlothMove you can easily manage your
          registration and stay protected
        </p>

        <h2>DBS Marketing Precention</h2>
        <p>
          When you activate DBS your details are cross-referenced across the sales and marketing databases.
          If your details are found the will be deleted. Your name and contact details to the DBS suppression
          list. The suppression list in only ever used for matching to new supplies from our data providers and
          contributors. If your suppression record ever matches a record being supplied, DBS will reject
          it to ensure the processing of your details doesn't begin again.
        </p>


        <div>
          <b-button
            variant="dark"
            class="mt-1 btn-sky"
            :disabled="antiSpamSubmitLoading || userPlan.plan !== 2"
            v-on:click="handleSubmitAntiSpam"
          >
            <span
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
              v-if="antiSpamSubmitLoading"
            ></span>
            Send Anti-Spam
          </b-button>
        </div>

      </b-col>

      <b-col
        v-if="homeAlertActive"
      >
        <h2>Land Registry Alert</h2>
        <p>
          The Land Registry Property Alert is a property monitoring service providing by the Land
          Registry. This service enables registered home owners to reduce their risk of property fraud.
          SlothMove will create your account, following which you simply need to activate it and add your
          desired properties.
        </p>


        <div>
          <b-button
            class="mt-1 btn-sky red-color-background"
            :disabled="homeAlertSubmitLoading || userPlan.plan !== 2"
            v-on:click="handleSubmitHomeAlert"
          >
            <span
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
              v-if="homeAlertSubmitLoading"
            ></span>
            Create my Account
          </b-button>
        </div>

        <b-row
          class="mt-3"
          v-if="homeAlertSuccess"
        >
          <b-col sm="6" md="3">
            <b-card>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_home_alert_success_land_register"
                  :src="security_home_alert_success_land_register"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Land Registry</h3>
                <b-card-text
                  class="text"
                >
                  Your account has been created, you'll need to add your property to the database to be protected
                </b-card-text>
              </div>
            </b-card>
          </b-col>

          <b-col sm="12">
            <h2>What happens next</h2>
            <p>
              You'll have an email from SlothMove and HM Land Registry. Open your email from HM Land Registry
              and log into your account using your email address and the temporary password provided by SlothMove.
              Once singned in. update password and add your property address to complete your property alert.
            </p>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        v-if="physicalActive"
      >
        <h2>Protect your home</h2>
        <p>
          An ADT alarm never goes unanswered. As soon as our UK monitoring centre receives a confirmed
          activation we take action. And with round the clock technical support and daily checks from
          the ADT Remote Operating Centre, we can ensure your system is always working and event fix up
          to 80% of issues remotely - it's support that never sleeps.
        </p>

        <b-row>
          <b-col
            sm="6"
            md="3"
          >
            <b-card
              class="security-card-physical"
            >
              <div
                class="center header-image"
              >

              </div>

              <div class="card-body-info">
                <div
                  class="text-left margin-buttom"
                >
                  <b-button
                    class="feature-physical-3"
                  >
                    Feature 3
                  </b-button>
                  <b-button
                    class="feature-physical-2 ml-1"
                  >
                    Feature 2
                  </b-button>
                </div>
                <h3 class="mt-2 text-center">Build my own system</h3>
                <b-card-text
                  class="text"
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam atque adipisci accusamus quis? Facilis temporibus id modi, itaque at odio facere minus possimus quam, soluta officia, consequatur aut iste cupiditate.
                </b-card-text>
              </div>
            </b-card>

            <b-button
              class="mt-1 btn-physical-1"
              block
            >
              Burglar Alarm
            </b-button>
          </b-col>

          <b-col
            sm="6"
            md="3"
          >
            <b-card
              class="security-card-physical"
            >
              <div
                class="center header-image"
              >

              </div>

              <div class="card-body-info">
                <div
                  class="text-left margin-buttom"
                >
                  <b-button
                    class="feature-physical-1"
                  >
                    Feature 1
                  </b-button>
                </div>
                <h3 class="mt-2 text-center">Outdoor Camera</h3>
                <b-card-text
                  class="text"
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam atque adipisci accusamus quis? Facilis temporibus id modi, itaque at odio facere minus possimus quam, soluta officia, consequatur aut iste cupiditate.
                </b-card-text>
              </div>
            </b-card>

            <b-button
              class="mt-1 btn-physical-2"
              block
            >
              CCTV
            </b-button>
          </b-col>

          <b-col
            sm="6"
            md="3"
          >
            <b-card
              class="security-card-physical"
            >
              <div
                class="center header-image"
              >

              </div>

              <div class="card-body-info">
                <div
                  class="text-left margin-buttom"
                >
                  <b-button
                    class="feature-physical-1"
                  >
                    Feature 1
                  </b-button>
                </div>
                <h3 class="mt-2 text-center">Indoor Camera</h3>
                <b-card-text
                  class="text"
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam atque adipisci accusamus quis? Facilis temporibus id modi, itaque at odio facere minus possimus quam, soluta officia, consequatur aut iste cupiditate.
                </b-card-text>
              </div>
            </b-card>

            <b-button
              class="mt-1 btn-physical-3"
              block
            >
              Pet Friendly
            </b-button>
          </b-col>
        </b-row>

        <b-row
          class="mt-5"
        >
          <b-col
            sm="6"
            md="3"
          >
            <b-card>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_physical"
                  :src="security_physical"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Physical</h3>
                <b-card-text
                  class="text"
                >
                  I have setup a home security system and my home is protected
                </b-card-text>

                <div
                  class="check-button"
                >
                  <b-form-checkbox
                    id="physical-1"
                    :checked="physical1"
                    name="check-physical-1"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col
            sm="6"
            md="3"
            offset-md="3"
          >
            <b-card>
              <div
                class="center header-image"
              >
                <b-img
                  v-if="security_physical"
                  :src="security_physical"
                  alt="basic img"
                />
              </div>

              <div class="card-body-info">
                <h3 class="mt-2 text-center">Physical</h3>
                <b-card-text
                  class="text"
                >
                  I am yet to sort my home security out and i need to protect y home
                </b-card-text>

                <div
                  class="check-button"
                >
                  <b-form-checkbox
                    class="check-button"
                    id="physical-2"
                    :checked="physical2"
                    name="check-physical-2"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BButton, BBadge, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'

import cyber from '@/assets/images/security/security_cyber.png'
import security_anti_spam from '@/assets/images/security/security_anti_spam.png'
import security_anti_spam_TPS from '@/assets/images/security/security_anti_spam_TPS.png'
import security_anti_spam_DBS from '@/assets/images/security/security_anti_spam_DBS.jpg'
import security_home_alert from '@/assets/images/security/security_home_alert.png'
import security_home_alert_success_land_register from '@/assets/images/security/security_home_alert_success_land_register.png'
import security_physical from '@/assets/images/security/security_physical.png'

import { getUserData } from '@/auth/utils'
import axiosCustom from '@axios'
import { mapState } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      cyber,
      cyberActive: false,
      cyberTextButtom: 'Launch Analysis',
      cyberLoading: false,
      showMessageEmptyCyberBreaches: false,
      cyberBreaches: [],
      cyberEmailForSendData: '',
      cyberSubmitEmailLoading: false,
      showMessageSendEmail: false,
      security_anti_spam,
      security_anti_spam_TPS,
      security_anti_spam_DBS,
      DBS: false,
      TPS: false,
      antiSpamActive: false,
      antiSpamSubmitLoading: false,
      security_home_alert,
      homeAlertActive: false,
      homeAlertSubmitLoading: false,
      homeAlertSuccess: false,
      security_physical,
      security_home_alert_success_land_register,
      physicalActive: false,
      physical1: false,
      physical2: false,
      securityTitle: 'SlothMove Security',
      loading: false,
    }
  },
  created(){
    const userData = getUserData()
    this.user = userData
    console.log("userData", userData)
    console.log("$store.state.app.userPlan", this.$store.state.app.userPlan)


  },
  methods: {
    showError: function(){
      this.$toast.error("Error processing request", {
        style: {
          backgroundColor: '#ff5252',
          color: '#fff'
        }
      })
    },
    handleValidatePlan: function(){
      console.log("$store.state.app.userPlan", this.$store.state.app.userPlan)
      return this.$store.state.app.userPlan.plan !== 2
    },
    handleActivateCyber: function(){
      // if(this.handleValidatePlan()){ return; }
      this.antiSpamActive = false;
      this.homeAlertActive = false;
      this.physicalActive = false;
      this.cyberActive = !this.cyberActive;
    },
    handleActivateAntiSpam: function(){
      // if(this.handleValidatePlan()){ return; }
      this.homeAlertActive = false;
      this.physicalActive = false;
      this.cyberActive = false;
      this.antiSpamActive = !this.antiSpamActive;
    },
    handleActivateHomeAlert: function(){
      // if(this.handleValidatePlan()){ return; }
      this.physicalActive = false;
      this.cyberActive = false;
      this.antiSpamActive = false;
      this.homeAlertSuccess = false;
      this.homeAlertActive = !this.homeAlertActive;
    },
    handleActivatePhysical: function(){
      // if(this.handleValidatePlan()){ return; }
      this.cyberActive = false;
      this.antiSpamActive = false;
      this.homeAlertActive = false;
      this.physicalActive = !this.physicalActive;
    },
    handleCyberLaunchAnalysis: function(){
      this.cyberLoading = true;
      axiosCustom.get(`sec/checkEmail?email=${this.user.email}`)
      .then( ( response ) => {
        if(response.data.length){
          if(response.data === 'noBreach'){
            this.showMessageEmptyCyberBreaches = true;
            this.cyberLoading = false;
            return;
          }

          Promise.all(
            response.data.map( breach => {
              return axiosCustom.get(`sec/checkCompany?company=${breach.Name}`)
            })
          ).then( (response) => {
            this.cyberBreaches = response.map( ({data}) => {
              return data;
            } )
          })
          .finally(() => {
            this.cyberLoading = false;
          })

        }

        if(!response.data.length){
          this.showMessageEmptyCyberBreaches = true;
          this.cyberLoading = false;
        }
      })
      .catch(() => {
        this.cyberLoading = false;
        this.showError();
      })
    },
    handleSubmitCyberEmail: function(){
      this.cyberSubmitEmailLoading = true;
      this.showMessageSendEmail = false;
      axiosCustom.get(`sec/checkDiffEmail?email=${this.cyberEmailForSendData}`)
      .then( ({data}) => {
        if(data === "OK"){
          this.showMessageSendEmail = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Excellent!`,
              icon: "CheckIcon",
              variant: "success",
              text: `A report has been sent to your email`,
            },
          });
        }
        else{
          this.showError();
        }
      })
      .finally(() => {
        this.cyberSubmitEmailLoading = false;
      })
    },
    handleSubmitAntiSpam: function(){
      if(this.DBS || this.TPS){
        this.antiSpamSubmitLoading = true;
        axiosCustom.get(`sec/spam?email=${this.user.email}&dbs=${this.DBS}&tps=${this.TPS}`)
        .then( ({data}) => {
          if(data === "OK"){
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Congratulations! 🎉`,
                icon: "CheckIcon",
                variant: "success",
                text: `Your anti-spam request has been sent and will be processed very soon`,
              },
            });
          }
          else{
            this.showError();
          }
        })
        .finally(() => {
          this.antiSpamSubmitLoading = false;
        })
      }
    },
    handleSubmitHomeAlert: function(){
      this.homeAlertSubmitLoading = true;
      this.homeAlertSuccess = false;
      axiosCustom.get(`sec/landRegistry?email=${this.user.email}`)
      .then( ({data}) => {
        if(data === "OK"){
          this.homeAlertSuccess = true;
          this.$toast.success("Succefull", {
            style: {
              backgroundColor: '#63b463',
              color: '#fff'
            }
          })
        }
        else{
          this.showError();
        }
      })
      .finally(() => {
        this.homeAlertSubmitLoading = false;
      })
    }
  },
  computed:{
    ...mapState("app", ["userPlan","lastMove"]),
  }
}
</script>

<style lang="scss">
  .security{

    .badge-success {
      background-color: #20476f !important;
      color: #f8edef !important;
      box-shadow: 0px 0px 10px #20476f;
      border-radius: 0.358rem;
    }

    .red-color{
      color: #ef3a5d !important;
    }

    .red-color-background{
      background-color: rgb(239, 58, 93) !important;
    }
    .blue-color-background{
      background-color: rgb(32, 71, 112);
    }
    .white-color{
      color: #fff;
    }
    .card{
      border-radius: 15px;
    }
    .center{
      display: flex;
      justify-content: center;
    }
    h1{
      color: #000;
    }
    h3{
      color: #000;
      font-weight: 600;
    }
    .main-cards{
     cursor: pointer;
    }
    .card-body {
     padding: 15px;
    }
    .header-image{
      height: 50px;
      img{
        height: 100%;
      }
    }
    .security-card-anti-spam{
      height: auto;
      color: #000;
      background-color: #fff;
    }
    .security-card{
      color: #000;
      background-color: #fff;
    }
    .security-card-active{
      color: #fff;
      background-color: rgb(32, 71, 112);
      h3{
        color: #fff;
      }
    }

    .security-card-physical{
      margin-bottom: 0px;
      .card-body{
        padding: 0;
      }
      .header-image{
        height: 150px;
      }

      .card-body-info{
        border-radius: 0px 0px 15px 15px;
        padding: 10px;
        background-color: #ddd;

        .feature-physical-3{
          padding: 5px;
          background-color: rgb(30, 57, 87) !important;
          border: none;
        }
        .feature-physical-2{
          padding: 5px;
          background-color: rgb(128, 0, 128) !important;
          border: none;
        }
        .feature-physical-1{
          padding: 5px;
          background-color: rgb(236, 6, 119) !important;
          border: none;
        }
      }
    }

    .btn-physical-1{
      background-color: rgb(255, 232, 89) !important;
      color: #000 !important;
      border: none;
    }

    .btn-physical-2{
      background-color: rgb(30, 57, 87) !important;
      border: none;
    }

    .btn-physical-2{
      background-color: rgb(236, 6, 119)!important;
      border: none;
    }

    .security-card-cyber-data{
      .card-body{
        padding: 0px;
        .header-card-cyber-data{
          background-color: rgb(30, 57, 87) !important;
          padding-top: 5px;
          border-radius: 15px 15px 0 0;

          h2{
            color: rgb(255, 232, 89) !important;
          }
        }
        .card-body-info{
          padding: 1.5rem;
          .img-cyber-data{
            height: 123px;
            width: 145px;
            background-color: rgb(30, 57, 87) !important;
            img{
              height: 100%;
              width: 100%;
            }
          }
          input{
            width: 300px;
          }
        }
      }
    }

    .check-button{
      display: flex;
      justify-content: center;
    }

    .security-card {
      height: 100%;
    }

    .security-card:hover {
      background-color: #204770!important;
      color:white!important;
      h3{
        color:white!important;
      }
    }

    /*
    @media (min-width: 768px) {
      .security-card {
        height: 316px;
      }
    }
    @media (min-width: 900px) {
      .security-card {
        height: 250px;
      }
    }
    */
  }
</style>
